<template>
  <div id="TrackingSection" />
</template>

<script>
export default {
  data () {
    return {
      showMetaPixel: false,
    }
  },
  mounted () {
    if (process.env.CLIENT && !process.env.DEV) {
    // if (process.env.CLIENT) {
      // Delay and nextTick so lower impact on early loading
      setTimeout(() => {
        this.$nextTick(() => {
          if (!this.trackingSection) return
          try { this.setGTag() } catch (e) { }
          try { this.setGoogleAnalytics() } catch (e) { }
          try { this.setMetaPixel() } catch (e) { }
        })
      }, 1000)
    }
  },
  computed: {
    trackingSection () {
      const el = document.getElementById('TrackingSection')
      if (!el) return null
      else return el
    }
  },
  methods: {
    setGTag () {
      const scriptElement = document.createElement('script')
      scriptElement.setAttribute('defer', 'defer')
      scriptElement.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_GTAG}`)
      this.trackingSection.appendChild(scriptElement)
      this.$nextTick(() => {
        window.dataLayer = window.dataLayer || []
        function gtag () { dataLayer.push(arguments) } /* eslint-disable-line no-undef */
        gtag('config', process.env.GOOGLE_GTAG)
      })
    },
    setGoogleAnalytics () {
      const scriptElement = document.createElement('script')
      scriptElement.setAttribute('defer', 'defer')
      scriptElement.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID}`)
      this.trackingSection.appendChild(scriptElement)
      this.$nextTick(() => {
        window.dataLayer = window.dataLayer || []
        function gtag () { dataLayer.push(arguments) } /* eslint-disable-line no-undef */
        gtag('js', new Date())
        gtag('config', process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID)
      })
    },
    setMetaPixel () {
      const scriptElement = document.createElement('script')
      scriptElement.setAttribute('defer', 'defer')
      scriptElement.setAttribute('type', 'text/javascript')
      const code = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '627089862667065');
      fbq('track', 'PageView');`
      try {
        scriptElement.appendChild(document.createTextNode(code))
        this.trackingSection.appendChild(scriptElement)
      } catch (e) {
        scriptElement.text = code
        this.trackingSection.appendChild(scriptElement)
      }
    }
  },
}
</script>
