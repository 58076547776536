<template>
  <div>
    <div class="row stl-content-container stl-content-container--xlarge q-mx-auto q-col-gutter-md">
      <div class="col-12 col-lg-9">
        <div class="row q-col-gutter-md">
          <div
            v-for="footerMenu of menus"
            :key="footerMenu.key"
            class="col-xs-12 col-md-6"
            :class="{'text-center q-mb-xl': $q.screen.lt.md}"
          >
            <AppContent
              tag="h3"
              class="q-ma-none text-weight-bold text-h5"
              :path="`navigation.footer.menus.${footerMenu.key}.title`"
            />
            <QList class="q-mt-md text-grey-5">
              <template v-for="menuItem of footerMenu.items">
                <QItem
                  v-if="(menuItem.route && doesRouteExist(menuItem.route)) || menuItem.href"
                  :key="menuItem.key"
                  class="q-pa-none"
                >
                  <QItemSection>
                    <router-link
                      v-if="menuItem.route && doesRouteExist(menuItem.route)"
                      class="hc-link q-py-sm"
                      :to="menuItem.route"
                    >
                      <AppContent
                        class="text-grey-5"
                        :path="`navigation.footer.menus.${footerMenu.key}.${menuItem.key}`"
                      />
                    </router-link>
                    <a
                      v-else-if="menuItem.href"
                      class="hc-link q-py-sm"
                      :target="menuItem.target"
                      :href="menuItem.href"
                    >
                      <AppContent
                        class="text-grey-5"
                        :path="`navigation.footer.menus.${footerMenu.key}.${menuItem.key}`"
                      />
                    </a>
                  </QItemSection>
                </QItem>
              </template>
            </QList>
          </div>
        </div>
      </div>
      <div
        class="col-xs-12 col-sm-12 col-lg-3"
        :class="{'q-mt-xl':$q.screen.lt.lg}"
      >
        <QCard class="bg-purple-2">
          <QCardSection>
            <div class="flex column">
              <img
                :src="cdnImg($t({ id: 'images.footerNewsletter' }), { width: 166 })"
                class="q-mx-auto"
                loading="lazy"
                alt="i"
                style="width:166px"
              >
              <AppContent
                tag="h3"
                class="q-mt-sm q-mb-none text-weight-bold text-h5"
                path="navigation.footer.menus.newsletter.title"
              />
              <AppContent
                class="q-mt-sm text-body2 text-grey-5"
                path="navigation.footer.menus.newsletter.text"
              />
            </div>
            <QInput
              v-model="newsletterFirstname"
              class="q-mt-sm text-primary"
              outlined
              dense
              type="text"
              label-color="grey-5"
              bg-color="white"
              :label="$t({ id: 'navigation.footer.menus.newsletter.firstname' })"
            />
            <QInput
              v-model="newsletterEmail"
              class="q-mt-sm text-primary"
              outlined
              dense
              type="text"
              label-color="grey-5"
              bg-color="white"
              :label="$t({ id: 'navigation.footer.menus.newsletter.email' })"
            />
            <div class="flex justify-end q-mt-sm">
              <HCBigButton
                label="navigation.footer.menus.newsletter.submit"
                @click="submitNewsletter"
              />
            </div>
          </QCardSection>
        </QCard>
      </div>
    </div>
  </div>
</template>

<script>
import { addContactToNewsletter } from 'hc-core/composables/misc'

export default {
  data () {
    return {
      newsletterEmail: '',
      newsletterFirstname: '',
      menus: [
        {
          key: 'vdc',
          items: [
            { key: 'articles', route: { name: 'blog' } },
            { key: 'twitter', href: 'https://twitter.com/VieDeComptable1', target: '_blank' },
            { key: 'facebook', href: 'https://www.facebook.com/groups/viedecomptable', target: '_blank' },
            { key: 'linkedin', href: 'https://www.linkedin.com/groups/13752316/', target: '_blank' },
            { key: 'videos', href: 'https://www.youtube.com/viedecomptable', target: '_blank' },
            { key: 'instagram', href: 'https://www.instagram.com/viedecomptable/', target: '_blank' }
          ]
        },
        {
          key: 'happycab',
          items: [
            { key: 'product', href: 'https://happycab.fr/produit', target: '_blank' },
            { key: 'pricing', href: 'https://happycab.fr/tarifs', target: '_blank' },
            { key: 'companies', href: 'https://happycab.fr/recherche/entreprises', target: '_blank' },
            { key: 'offers', href: 'https://happycab.fr/recherche/offres-emploi', target: '_blank' },
            { key: 'news', href: 'https://happycab.fr/media/blog', target: '_blank' },
            { key: 'account', href: 'https://app.happycab.fr', target: '_blank' }
          ]
        }
      ]
    }
  },
  methods: {
    addContactToNewsletter,
    doesRouteExist (route) {
      const hasRoutes = this.$_.has(this.$_.filter(this.$router.getRoutes(), (r) => r.name === route.name), '[0]', false)
      return hasRoutes
    },
    async submitNewsletter () {
      try {
        await this.addContactToNewsletter(this.newsletterEmail, this.newsletterFirstname)
        this.newsletterEmail = ''
        this.newsletterFirstname = ''
      } catch (e) { this.useLogger(e) }
    },
  }
}
</script>
