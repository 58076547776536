<template>
  <div ref="AppLogo">
    <div
      v-if="!miniLogo"
      class="flex items-center"
    >
      <img
        class="gt-md"
        src="~/assets/logovdc.svg"
        :style="'object-fit:contain; object-align:center; max-height:40px;' + ($q.screen.width > 1060 ? 'width:200px;' : 'width:180px;')"
      >
      <img
        class="lt-lg"
        src="~/assets/logovdc.svg"
        :style="`width:${miniWidth}; object-fit:contain; object-align:center;`"
      >
    </div>
    <div
      v-else
      class="flex items-center"
    >
      <img
        src="~/assets/logovdc.svg"
        :style="`width:${miniWidth}; object-fit:contain; object-align:center;`"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    miniLogo: {
      type: Boolean,
      default: false
    },
    miniWidth: {
      type: String,
      default: '40px'
    }
  },
}
</script>
