<template>
  <QLayout view="hHh lpR fFf">
    <MainHeader />
    <QAjaxBar
      v-if="hasLoadingBar"
      ref="loadingBar"
      position="top"
      color="secondary"
      size="3px"
    />

    <QPageContainer>
      <router-view v-slot="{ Component }">
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
          appear
          :duration="300"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </QPageContainer>

    <div
      v-if="$route.meta.hideFooter !== true"
      class="q-pa-xl"
    >
      <AppFooter />
    </div>
    <CookiePopup />
  </QLayout>
</template>

<script>
import AppFooter from 'src/components/nav/Footer'
import MainHeader from 'src/components/nav/MainHeader'
import CookiePopup from 'hc-core/components/popups/cookie-popup'

export default {
  components: { MainHeader, AppFooter, CookiePopup },
  async preFetch ({ store, currentRoute }) {
    // Fetch only once platformAds
    const today = new Date().toISOString().slice(0, 10)
    await store.dispatch('content/list', {
      collection: 'platformAds',
      nbResultsPerPage: 20,
      rawFilters: [`fields.startDate GTE ${today}T00:00:00.000Z`, `fields.endDate LTE ${today}T23:59:59.999Z`],
      metadata: { active: true }
    })
  },
  data () {
    return {
      routeTransitionName: '',
      checkoutOpenedDialog: false,
      hasLoadingBar: false,
      loadingBarRouteGuard: _ => _,
    }
  },
  async mounted () {
    // Don’t show AjaxBar before first navigation for consistency
    this.loadingBarRouteGuard = this.$router.afterEach(this.showLoadingBar)
    await this.$store.dispatch('common/fetchCategories', { forceRefresh: true })
    await this.$store.dispatch('common/fetchCustomAttributes', { forceRefresh: true })
  },
  methods: {
    showLoadingBar () {
      this.hasLoadingBar = true
      this.loadingBarRouteGuard() // unregister
    }
  },
}
</script>

<style lang="sass">
body
  background: $grey-1
</style>
